import { Link, useHistory } from "react-router-dom";
import Logo from "../assets/logo/logo-braice.jpg";

const RootLayoutAdmin = ({ children }) => {
    const history = useHistory();

    const handleLogout = async () => {
        const token = localStorage.getItem("token");

        if (!token) {
            console.error("No token found");
            return;
        }

        try {
            const response = await fetch(
                "https://jeffcapstoneapi.wd99p.com/api/v1/logout",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Logout failed");
            }
            
            const result = await response.json();
            console.log(result);
            localStorage.removeItem("token");
            localStorage.removeItem("access");
            history.push("/login");
        } catch (error) {
            console.error("Logout failed: ", error);
        }
    };

    return (
        <div className="root-layout-admin">
            <aside className="w-64 h-full fixed bg-gray-800 text-white">
                <nav className="h-full p-3 flex flex-col">
                    <div className="flex items-center gap-3">
                        <div>
                            <img
                                src={Logo}
                                alt="Braice Logo"
                                className="w-14"
                            />
                        </div>
                        <div>
                            <p>PRINTS & APPAREL</p>
                            <p>Prints with Style</p>
                        </div>
                    </div>
                    <hr className="my-3" />
                    <div className="w-full flex flex-col gap-5 text-center flex-grow">
                        <ul className="kuyaw w-full divide-y divide-gray-200">
                            <Link to="/admin/dashboard">
                                <li className="p-3 hover:bg-slate-500/20">
                                    DASHBOARD
                                </li>
                            </Link>
                            <Link to="/admin/apparels">
                                <li className="p-3 hover:bg-slate-500/20">
                                    APPARELS
                                </li>
                            </Link>
                            <Link to="/admin/orders">
                                <li className="p-3 hover:bg-slate-500/20">
                                    ORDERS
                                </li>
                            </Link>
                            <Link to="/admin/customers">
                                <li className="p-3 hover:bg-slate-500/20">
                                    CUSTOMERS
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div
                        onClick={handleLogout}
                        className="p-3 hover:bg-slate-500/20 cursor-pointer text-center"
                    >
                        Logout
                    </div>
                </nav>
            </aside>
            {children}
        </div>
    );
};

export default RootLayoutAdmin;
