import { useState } from "react";

const AddItem = ({
    formData,
    handleChange,
    handleFileChange,
    handleSubmit,
    handleCancel,
    success,
    setSuccss,
}) => {
    return (
        <div className="w-full  px-10">
            <h1 className="text-3xl font-bold text-center mb-5">
                Add New Item
            </h1>
            {success && (
                <div className="relative text-center text-green-700 bg-green-400 py-2 font-bold rounded-xl w-full">
                    {success}
                    <div
                        onClick={() => setSuccss("")}
                        className="absolute top-2 right-4 cursor-pointer"
                    >
                        x
                    </div>
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className="mb-2">
                    <label htmlFor="image" className="block">
                        Upload Image
                    </label>
                    <input
                        onChange={handleFileChange}
                        type="file"
                        id="image"
                        name="image"
                        className="w-full mt-1 border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="name" className="block">
                        Item Name
                    </label>
                    <input
                        onChange={handleChange}
                        value={formData.name}
                        type="text"
                        id="name"
                        name="name"
                        className="w-full mt-1 border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="description" className="block">
                        Description
                    </label>
                    <textarea
                        onChange={handleChange}
                        value={formData.description}
                        type="text"
                        id="description"
                        name="description"
                        className="w-full mt-1 border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                    ></textarea>
                </div>
                <div className="mb-2">
                    <label htmlFor="size" className="block">
                        Size
                    </label>
                    <select
                        onChange={handleChange}
                        value={formData.size}
                        id="size"
                        name="size"
                        className="w-full mt-1 border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                    >
                        <option value="" disabled>
                            Select Category
                        </option>
                        <option value="S">Small</option>
                        <option value="M">Medium</option>
                        <option value="L">Large</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label htmlFor="price" className="block">
                        Price
                    </label>
                    <input
                        onChange={handleChange}
                        value={formData.price}
                        type="number"
                        id="price"
                        name="price"
                        className="w-full mt-1 border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="quantity" className="block">
                        Quantity
                    </label>
                    <input
                        onChange={handleChange}
                        value={formData.quantity}
                        type="number"
                        id="quantity"
                        name="quantity"
                        className="w-full mt-1 border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="category" className="block">
                        Category
                    </label>
                    <select
                        onChange={handleChange}
                        value={formData.category}
                        id="category"
                        name="category"
                        className="w-full mt-1 border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                    >
                        <option value="" disabled>
                            Select Category
                        </option>
                        <option value="Tshirt">Tshirt</option>
                        <option value="Hoodie">Hoodie</option>
                        <option value="Shorts">Shorts</option>
                        <option value="Cap">Cap</option>
                    </select>
                </div>
                <div className="w-full flex gap-5 mt-5">
                    <button
                        type="submit"
                        className="w-full py-3 rounded-lg text-white font-bold bg-green-700 hover:bg-green-600"
                    >
                        Add Item
                    </button>
                    <button
                        type="reset"
                        onClick={handleCancel}
                        className="w-full py-3 rounded-lg font-bold bg-slate-400 hover:bg-slate-300"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddItem;
