import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Account = () => {
    const history = useHistory();
    const [userData, setUserData] = useState({});
    const [error, setError] = useState("");

    const fetchUserData = async () => {
        const token = localStorage.getItem("token");

        if (!token) {
            console.error("No token found");
            return;
        }

        try {
            const response = await fetch("https://jeffcapstoneapi.wd99p.com/api/v1/user", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch user data");
            }

            const result = await response.json();
            setUserData(result);
            console.log(result);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const handleLogout = async () => {
        const token = localStorage.getItem("token");

        if (!token) {
            console.error("No token found");
            return;
        }

        try {
            const response = await fetch(
                "https://jeffcapstoneapi.wd99p.com/api/v1/logout",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Logout failed");
            }

            const result = await response.json();
            console.log(result);
            localStorage.removeItem("token");
            localStorage.removeItem("access");
            history.push("/login");
        } catch (error) {
            console.error("Logout failed: ", error);
        }
    };
    return (
        <div className="pt-32 pb-10 bg-white">
            <div className="max-w-md mx-auto p-6 shadow-xl bg-slate-100 rounded-lg">
                <div className="flex items-center justify-center">
                    <img
                        src="https://via.placeholder.com/150"
                        alt="User"
                        className="w-24 h-24 rounded-full"
                    />
                </div>
                <div className="mt-6">
                    <h2 className="text-2xl font-semibold text-center">
                        Account Information
                    </h2>
                    <div className="mt-4">
                        <div className="mb-4">
                            <label className="block text-gray-700">
                                First Name:
                            </label>
                            <p className="text-gray-900">{userData.first_name}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">
                                Last Name:
                            </label>
                            <p className="text-gray-900">{userData.last_name}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">
                                Contact:
                            </label>
                            <p className="text-gray-900">{userData.contact}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">
                                Address:
                            </label>
                            <p className="text-gray-900">
                                {userData.address}
                            </p>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">
                                Email:
                            </label>
                            <p className="text-gray-900">
                                {userData.email}
                            </p>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">
                                Password:
                            </label>
                            <p className="text-gray-900">••••••••</p>
                        </div>
                    </div>
                    <div className="flex justify-between mt-6">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                            Edit
                        </button>
                        <button
                            onClick={handleLogout}
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
