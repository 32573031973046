import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import AddItem from "../../components/AddItem";
import EditItem from "../../components/EditItem";

const Apparels = () => {
    const [apparels, setApparels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        size: "",
        price: "",
        quantity: "",
        category: "",
        image: null,
    });

    const [editFormData, setEditFormData] = useState({
        id: "",
        name: "",
        description: "",
        size: "",
        price: "",
        quantity: "",
        category: "",
    });

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleEditChange = (e) => {
        setEditFormData({
            ...editFormData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0],
        });
    };

    const fetchAllApparels = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/apparels?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);

            setTotalPages(result.last_page);
            setApparels(result.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    const fetchApparel = async (id) => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/apparels/${id}/show`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application.json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result.data);
            setEditFormData(result.data);
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    const addItem = async () => {
        const formDataObj = new FormData();
        formDataObj.append("name", formData.name);
        formDataObj.append("description", formData.description);
        formDataObj.append("size", formData.size);
        formDataObj.append("price", formData.price);
        formDataObj.append("quantity", formData.quantity);
        formDataObj.append("category", formData.category);
        formDataObj.append("image", formData.image);

        const token = localStorage.getItem("token");

        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/apparels/add`,
                {
                    method: "POST",
                    body: formDataObj,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);
            setSuccess(result.success);
            fetchAllApparels();
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    const updateItem = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/apparels/${editFormData.id}/edit`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application.json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(editFormData),
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result.message); 
            setSuccess(result.message); 
            fetchAllApparels();
        } catch (error) {
            console.error("Update failed: ", error);
        }
    };

    const emptyFields = () => {
        setFormData({
            name: "",
            description: "",
            size: "",
            price: "",
            quantity: "",
            category: "",
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (addModalOpen) {
            addItem();
        } else if (editModalOpen) {
            updateItem();
        }
        emptyFields();
    };

    const handleCancel = () => {
        emptyFields();
        setAddModalOpen(false);
        setEditModalOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleEdit = (id) => {
        console.log(id);
        fetchApparel(id);
    };

    const handleDelete = async (id) => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/apparels/${id}/delete`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: `application/json`,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);
            fetchAllApparels();
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    useEffect(() => {
        fetchAllApparels();
    }, [currentPage]);

    return (
        <div className="ml-64 bg-slate-300 p-3">
            <div className="w-full bg-gray-700 p-5 rounded-lg">
                <div className="flex justify-between items-center">
                    <h1 className="text-white text-4xl font-bold mb-5">
                        APPAREL RECORDS
                    </h1>
                    <button
                        onClick={() => setAddModalOpen(true)}
                        className="px-6 py-3 bg-green-700 hover:bg-green-600 rounded-lg text-white font-bold"
                    >
                        Add Item
                    </button>
                </div>

                <Modal
                    open={addModalOpen}
                    onClose={() => setAddModalOpen(false)}
                >
                    <AddItem
                        formData={formData}
                        handleChange={handleChange}
                        handleFileChange={handleFileChange}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                        success={success}
                        setSuccss={setSuccess}
                    />
                </Modal>

                <Modal
                    open={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                >
                    <EditItem
                        editFormData={editFormData}
                        handleEditChange={handleEditChange}
                        // handleFileChange={handleFileChange}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                        success={success}
                        setSuccss={setSuccess}
                    />
                </Modal>

                <div className="w-full mt-3">
                    <table className="w-full text-white bg-gray-800 rounded-lg shadow-lg divide-y divide-gray-600">
                        <thead className="bg-gray-900">
                            <tr>
                                <th className="p-3 text-center w-5">#</th>
                                <th className="p-3 text-center w-40">Name</th>
                                <th className="p-3 text-center max-w-10">
                                    Description
                                </th>
                                <th className="p-3 text-center w-5">Size</th>
                                <th className="p-3 text-center w-10">Price</th>
                                <th className="p-3 text-center w-5">Qty</th>
                                <th className="p-3 text-center w-10">
                                    Category
                                </th>
                                <th className="p-3 text-center w-32">Action</th>
                            </tr>
                        </thead>

                        {isLoading ? (
                            <tbody className="divide-y divide-gray-700">
                                <tr>
                                    <td
                                        colSpan="8"
                                        className="text-center py-5"
                                    >
                                        <h1 className="text-4xl font-bold text-white">
                                            Loading. . .
                                        </h1>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody className="divide-y divide-gray-700">
                                {apparels.map((apparel, index) => (
                                    <tr
                                        key={apparel.id}
                                        className="bg-gray-600 hover:bg-gray-500 transition-colors"
                                    >
                                        <td className="p-3">{apparel.id}</td>
                                        <td className="p-3">{apparel.name}</td>
                                        <td className="p-3 max-w-10">
                                            <div className="truncate">
                                                {apparel.description}
                                            </div>
                                        </td>
                                        <td className="p-3">{apparel.size}</td>
                                        <td className="p-3">{apparel.price}</td>
                                        <td className="p-3">
                                            {apparel.quantity}
                                        </td>
                                        <td className="p-3">
                                            {apparel.category}
                                        </td>
                                        <td className="p-3">
                                            <button
                                                onClick={() => {
                                                    handleEdit(apparel.id);
                                                    setEditModalOpen(true);
                                                }}
                                                className="text-blue-500 hover:text-blue-300"
                                            >
                                                Edit
                                            </button>{" "}
                                            |{" "}
                                            <button
                                                onClick={() =>
                                                    handleDelete(apparel.id)
                                                }
                                                className="text-red-500 hover:text-red-300"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                    <div className="mt-4 flex justify-center">
                        {Array.from(
                            { length: totalPages },
                            (_, i) => i + 1
                        ).map((page) => (
                            <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                className={`px-3 py-1 mx-1 rounded-md ${
                                    currentPage === page
                                        ? "bg-blue-500 text-white"
                                        : "bg-gray-500 text-gray-800"
                                }`}
                            >
                                {page}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Apparels;
