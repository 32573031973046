import NotFound from "../assets/images/page-not-found.png";

const PageNotFound = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center bg-slate-400">
      <img src={NotFound} alt="404 Not Found" className="object-contain"/>
    </div>
  )
}

export default PageNotFound