import { Link, useHistory } from "react-router-dom";
import Logo from "../assets/logo/logo-braice.jpg";
import { useState } from "react";

const Login = () => {
    const history = useHistory();

    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFocus = () => {
        setMessage("");
    };

    const login = async () => {
        try {
            let result = await fetch("https://jeffcapstoneapi.wd99p.com/api/v1/login", {
                method: "POST",
                body: JSON.stringify(formData),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });

            result = await result.json();
            // console.log(result);
            // console.log(result.data.user.user_type);
            // console.log(result.data.redirect_url);

            if (!result.access_token) {
                return setMessage(result.message);
            }

            localStorage.setItem("token", result.access_token);
            localStorage.setItem("access", result.data.user.user_type);

            // const access = localStorage.getItem("access");
            // if (access === "admin") {
            //     history.push("/admin/dashboard");
            // } else {
            //     history.push("/login");
            // }

            history.push(result.data.redirect_url);
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    const emptyFields = () => {
        setFormData({
            email: "",
            password: "",
        });
    };

    const isEmpty = () => {
        return formData.email === "" || formData.password === "";
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        login();
        emptyFields();
    };

    return (
        <div className="w-full bg-slate-400 flex justify-center items-center">
            <div className="w-full sm:rounded-lg sm:my-20 sm:mx-20 md:mx-32 lg:mx-52 xl:mx-80 bg-white grid grid-cols-1 md:grid-cols-2 overflow-hidden">
                <img
                    src={Logo}
                    alt="Braice Logo"
                    className="object-cover w-full h-full hidden md:block"
                />
                <div className="flex flex-col justify-center items-center w-full p-10">
                    <h1 className="text-3xl font-semibold">Login</h1>
                    <hr className="my-2" />
                    {message && (
                        <div className="w-full text-center mb-2 py-2 rounded-xl bg-red-500/50 text-red-500 font-bold">
                            {message}
                        </div>
                    )}

                    <div className="w-full">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="email" className="block">
                                    Email
                                </label>
                                <input
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    value={formData.email}
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="block">
                                    Password
                                </label>
                                <input
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    value={formData.password}
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 rounded-lg bg-lime-500"
                            >
                                Login
                            </button>
                        </form>
                        <div className="text-xs w-full text-blue-500">
                            <p className="my-2 cursor-pointer hover:underline">
                                Forgot your password?
                            </p>
                            <Link to="/register">
                                <p className="cursor-pointer hover:underline">
                                    Create accout
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
