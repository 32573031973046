import React from "react";

const CartCard = ({ item }) => {
    const handleDelete = async (id) => {
        console.log(id)
        const token = localStorage.getItem("token");

        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/cart/${id}/delete`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: `application/json`,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    return (
        <div className="w-full">
            <div className="w-full flex py-3">
                <div>
                    <img
                        src={
                            item.apparel.image_url
                                ? `https://jeffcapstoneapi.wd99p.com${item.apparel.image_url}`
                                : `https://static.vecteezy.com/system/resources/thumbnails/022/059/000/small_2x/no-image-available-icon-vector.jpg`
                        }
                        alt="Tshirt"
                        className="w-28"
                    />
                </div>
                <div className="w-full p-2 flex justify-between">
                    <div>
                        <h3 className="font-semibold">{item.apparel.name}</h3>
                        <p>Size: {item.apparel.size}</p>
                        <h4 className="text-red-500 font-bold text-lg">
                            {item.apparel.price} PHP
                        </h4>
                        <div className="flex">
                            <div className="border-slate-500/90 border-2 px-2 cursor-pointer hover:bg-slate-200">
                                -
                            </div>
                            <div className="border-slate-500/90 border-y-2 px-2">
                                {item.quantity}
                            </div>
                            <div className="border-slate-500/90 border-2 px-2 cursor-pointer hover:bg-slate-200">
                                +
                            </div>
                        </div>
                        <p></p>
                    </div>
                    <div onClick={() => handleDelete(item.id)} className="flex items-end cursor-pointer hover:text-red-500 hover:font-bold">
                        <p>remove</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartCard;
