import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "./Modal";

const ItemDetails = ({ setSidebarOpen }) => {
    const { id } = useParams();
    const [userData, setUserData] = useState({});
    const [apparel, setApparel] = useState({});
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const token = localStorage.getItem("token");
    const [error, setError] = useState(false);

    const fetchApparel = async () => {
        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/apparels/${id}/details`,
                {
                    Accept: "application.json",
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            setApparel(result.data);
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    const fetchUserData = async () => {
        if (!token) {
            console.error("No token found");
            return;
        }

        try {
            const response = await fetch("https://jeffcapstoneapi.wd99p.com/api/v1/user", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch user data");
            }

            const result = await response.json();
            setUserData(result);
            // console.log(result);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setError(error.message);
        }
    };

    const handleAddToCart = async () => {
        if (!token) {
            console.error("No token found");
            return;
        }

        const data = {
            user_id: userData.id,
            apparel_id: id,
            quantity: quantity,
        };
        try {
            const response = await fetch(
                "https://jeffcapstoneapi.wd99p.com/api/v1/cart/add",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "applicatoin/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(data),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch user data");
            }

            const result = await response.json();
            // setUserData(result);
            console.log(result);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setError(error.message);
        }
    };

    const handleAdd = () => {
        setQuantity((prevQty) => prevQty + 1);
    };

    const handleMinus = () => {
        setQuantity((prevQty) => (prevQty > 1 ? prevQty - 1 : 1));
    };

    useEffect(() => {
        fetchApparel();
        fetchUserData();
    }, []);

    return (
        <div className="w-full sm:px-20 bg-amber-900/5 pt-40 pb-20">
            <div className="w-full">ItemDetails {id}</div>
            <div className="w-full grid lg:grid-cols-11">
                <div className="bg-slate-500 lg:col-span-6">
                    <div className="md:flex-shrink-0">
                        <img
                            className="h-full w-full object-contain"
                            src={
                                apparel.image_url
                                    ? `https://jeffcapstoneapi.wd99p.com${apparel.image_url}`
                                    : `https://static.vecteezy.com/system/resources/thumbnails/022/059/000/small_2x/no-image-available-icon-vector.jpg`
                            }
                            alt="Apparel"
                        />
                    </div>
                </div>
                <div className="w-full lg:col-span-5 pl-16 py-5">
                    <div>
                        <h1 className="text-4xl font-semibold">
                            {apparel.name}
                        </h1>
                        <h3 className="text-2xl text-red-700 font-extrabold">
                            ₱ {apparel.price}
                        </h3>
                    </div>
                    <div className="my-4 border-b-2 border-gray-300"></div>
                    <div>
                        <h3 className="text-xl mb-5">
                            Stock: {apparel.quantity}
                        </h3>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-between font-bold">
                            <h3>Size</h3>
                            <h3 className="flex justify-center gap-1 cursor-pointer hover:text-green-800">
                                <span className="material-symbols-outlined">
                                    design_services
                                </span>
                                Size Guide
                            </h3>
                        </div>
                        <div className="flex gap-5 mt-2">
                            <div className="px-8 py-5 border border-gray-500 bg-white">
                                {apparel.size}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h2 className="text-2xl font-semibold uppercase">
                            Product Description
                        </h2>
                        <p>{apparel.description}</p>
                    </div>
                    <div className="mt-5">
                        <h2 className="text-2xl font-semibold uppercase">
                            Shipping and Delivery
                        </h2>
                        <p>
                            - Shipping and Delivery within Biliran <br />{" "}
                            Standard delivery time: 2-6 business days. <br />
                            See all delivery options and prices upon check-out.{" "}
                            <br />- Shipping and Delivery outside Biliran
                            <br /> On orders outside Biliran, a shipping fee
                            will be added to your order.
                            <br /> Standard delivery time: 2-6 business days.
                            <br /> See all delivery options and prices upon
                            check-out.
                        </p>
                    </div>
                    <div className="flex justify-center items-center mt-5">
                        <h1 className="mr-5">Quantity</h1>
                        <div
                            onClick={handleMinus}
                            className="border-slate-500/90 border-2 px-4 py-2 cursor-pointer hover:bg-slate-200"
                        >
                            -
                        </div>
                        <div className="border-slate-500/90 border-y-2 px-4 py-2 font-bold text-lime-700">
                            {quantity}
                        </div>
                        <div
                            onClick={handleAdd}
                            className="border-slate-500/90 border-2 px-4 py-2 cursor-pointer hover:bg-slate-200"
                        >
                            +
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            if (!token) {
                                setOpen(true);
                            } else {
                                handleAddToCart();
                            }
                        }}
                        className="flex items-center justify-center gap-3 w-full py-4 mt-3 text-lg font-bold bg-green-800 hover:bg-green-700 text-white"
                    >
                        <span className="material-symbols-outlined">
                            add_shopping_cart
                        </span>
                        Add to Cart
                    </button>
                </div>
            </div>
            <Modal open={open} onClose={() => setOpen(false)}>
                <h1 className="text-2xl font-semibold text-green-600">
                    Greetings! valued customer
                </h1>
                <p className="my-3">
                    To complete your purchase, please log in to your account. If
                    you don't have an account yet, registering is quick and
                    easy!
                </p>
                <p className="mb-3">Thank you for shopping with us!</p>
                <Link to="/login">
                    <button className="py-4 bg-green-800 hover:bg-green-700 w-full font-semibold text-white uppercase">
                        Login
                    </button>
                </Link>
                <Link to="/register">
                    <button className="py-4 mt-3  border-green-500 hover:bg-green-200 border-2 w-full font-semibold text-green-500 uppercase">
                        Register
                    </button>
                </Link>
            </Modal>
        </div>
    );
};

export default ItemDetails;
