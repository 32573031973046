import { useEffect, useState } from "react";

const Customers = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchAllUsers = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(
                "https://jeffcapstoneapi.wd99p.com/api/v1/users/all",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);
            setUsers(result);
            setIsLoading(false);
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    const handleDelete = async (id) => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/users/${id}/delete`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: `application/json`,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);
            fetchAllUsers();
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, []);

    return (
        <div className="ml-64 bg-slate-300 p-3 h-screen">
            <div className="w-full bg-gray-700 p-5 rounded-lg h-full">
                <h1 className="text-white text-4xl font-bold mb-5">
                    CUSTOMER RECORDS
                </h1>
                <div className="w-full p-5">
                    <table className="w-full text-white bg-gray-800 rounded-lg shadow-lg divide-y divide-gray-600">
                        <thead className="bg-gray-900">
                            <tr>
                                <th className="p-3 text-left w-8">#</th>
                                <th className="p-3 text-left w-32">
                                    First Name
                                </th>
                                <th className="p-3 text-left w-32">
                                    Last Name
                                </th>
                                <th className="p-3 text-left w-40">Contact</th>
                                <th className="p-3 text-left w-40">Address</th>
                                <th className="p-3 text-left w-40">Email</th>
                                <th className="p-3 text-left w-32">Password</th>
                                <th className="p-3 text-left w-32">Action</th>
                            </tr>
                        </thead>

                        {isLoading ? (
                            <tbody className="divide-y divide-gray-700">
                                <tr>
                                    <td
                                        colSpan="8"
                                        className="text-center py-5"
                                    >
                                        <h1 className="text-4xl font-bold text-white">
                                            Loading. . .
                                        </h1>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody className="divide-y divide-gray-700">
                                {users.map((user, index) => (
                                    <tr
                                        key={user.id}
                                        className="bg-gray-600 hover:bg-gray-500 transition-colors"
                                    >
                                        <td className="p-3 truncate">
                                            {index + 1}
                                        </td>
                                        <td className="p-3 truncate">
                                            {user.first_name}
                                        </td>
                                        <td className="p-3 truncate">
                                            {user.last_name}
                                        </td>
                                        <td className="p-3 truncate">
                                            {user.contact}
                                        </td>
                                        <td className="p-3 truncate">
                                            {user.address}
                                        </td>
                                        <td className="p-3 truncate">
                                            {user.email}
                                        </td>
                                        <td className="p-3 truncate">
                                            {user.password}
                                        </td>
                                        <td className="p-3 truncate">
                                            {user.user_type === "admin" ? (
                                                <></>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        handleDelete(user.id)
                                                    }
                                                    className="text-red-500 hover:text-red-300"
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Customers;
