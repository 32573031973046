import { Link } from "react-router-dom";
import Logo from "../assets/logo/logo-braice.jpg";

import { useState } from "react";
import { useHistory } from "react-router-dom";

const Register = () => {
    const history = useHistory();

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        contact: "",
        address: "",
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const register = async () => {
        let result = await fetch("https://jeffcapstoneapi.wd99p.com/api/v1/register", {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        result = await result.json();
        console.log(result);
        history.push("/login");
    };

    const emptyFields = () => {
        setFormData({
            first_name: "",
            last_name: "",
            contact: "",
            address: "",
            email: "",
            password: "",
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        register();
        emptyFields();
    };

    return (
        <div className="w-full bg-slate-400 flex justify-center items-center">
            <div className="w-full sm:mx-20 sm:my-20 md:mx-3 lg:mx-20 xl:mx-52 sm:rounded-lg bg-white grid grid-cols-1 md:grid-cols-2 overflow-hidden">
                <img
                    src={Logo}
                    alt="Braice Logo"
                    className="object-cover h-full hidden md:block"
                />
                <div className="flex flex-col justify-center items-center w-full p-3">
                    <h1 className="text-3xl font-semibold">Signup</h1>
                    <hr className="my-2" />
                    <div className="w-full px-3 lg:px-20">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="first_name" className="block">
                                    First Name
                                </label>
                                <input
                                    onChange={handleChange}
                                    value={formData.first_name}
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="last_name" className="block">
                                    Last Name
                                </label>
                                <input
                                    onChange={handleChange}
                                    value={formData.last_name}
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contact" className="block">
                                    Contact
                                </label>
                                <input
                                    onChange={handleChange}
                                    value={formData.contact}
                                    type="number"
                                    id="contact"
                                    name="contact"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address" className="block">
                                    Address
                                </label>
                                <input
                                    onChange={handleChange}
                                    value={formData.address}
                                    type="text"
                                    id="address"
                                    name="address"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="block">
                                    Email
                                </label>
                                <input
                                    onChange={handleChange}
                                    value={formData.email}
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="block">
                                    Password
                                </label>
                                <input
                                    onChange={handleChange}
                                    value={formData.password}
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-lime-500 focus:border-blue-lime-500"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 rounded-lg bg-lime-500"
                            >
                                Register
                            </button>
                        </form>
                        <div className="text-xs w-full ">
                            <Link to="/login">
                                <p className="my-2">
                                    Already have an account?
                                    <span className="text-blue-500 cursor-pointer hover:underline">
                                        Login here
                                    </span>
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
