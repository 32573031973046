import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo/logo-braice.jpg";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import Cart from "../components/Cart";

export default function RootLayout({ children }) {
    const location = useLocation();
    const [navFlag, setNavFlag] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [page, setPage] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const pathSegments = location.pathname.split("/").filter(Boolean); // Split the path and filter out empty segments
        const lastSegment = pathSegments[pathSegments.length - 1]; // Get the last segment
        console.log("URL segment:", lastSegment); // Log the segment (e.g., 'contact')
        setPage(lastSegment);
    }, [location.pathname]);

    return (
        <div className="root-layout">
            <header>
                <nav
                    className={`fixed w-full z-10 flex justify-between items-center h-20 px-5 md:px-10 lg:px-20 transition-colors duration-300 ${
                        scrolled
                            ? "bg-white text-black shadow-2xl"
                            : `bg-transparent ${
                                  page === "contact" || page === "details" || page === "account"
                                      ? "text-black shadow-xl"
                                      : "text-white"
                              }`
                    }`}
                >
                    <div>
                        <img
                            src={Logo}
                            alt="Logo"
                            className="w-16 rounded-lg"
                        />
                    </div>

                    <ul className="navs hidden lg:flex gap-5">
                        <li>
                            <Link to="/">HOME</Link>
                        </li>
                        <li>
                            <Link to="/shop">SHOP</Link>
                        </li>
                        <li>
                            <Link to="/about">ABOUT US</Link>
                        </li>
                        <li>
                            <Link to="/contact">CONTACT US</Link>
                        </li>
                    </ul>

                    <div className="hidden lg:flex gap-5 justify-center items-center">
                        <button onClick={() => setSidebarOpen(!sidebarOpen)}>
                            <span className="material-symbols-outlined text-3xl">
                                shopping_cart
                            </span>
                        </button>
                        {token ? (
                            <Link to="/account">
                                <span className="material-symbols-outlined text-3xl">
                                    account_circle
                                </span>
                            </Link>
                        ) : (
                            <Link to="/login">
                                <span className="material-symbols-outlined text-3xl">
                                    account_circle
                                </span>
                            </Link>
                        )}
                    </div>

                    {/* Hamburger */}
                    <div
                        onClick={() => setNavFlag(!navFlag)}
                        className="lg:hidden z-30 cursor-pointer "
                    >
                        {!navFlag ? (
                            <span className="material-symbols-outlined">
                                menu
                            </span>
                        ) : (
                            <span className="material-symbols-outlined text-white">
                                close
                            </span>
                        )}
                    </div>

                    {/* Mobile dropdown menu */}
                    <div
                        className={`absolute ${
                            navFlag ? "left-0" : "left-[100%]"
                        } top-0 w-full bg-slate-500/90 px-4 py-7 flex flex-col z-20`}
                    >
                        <h1 className="text-white">BRAICE</h1>
                        <ul className="flex flex-col items-center gap-6 text-white">
                            <li>
                                <Link to="/">HOME</Link>
                            </li>
                            <li>
                                <Link to="/shop">SHOP</Link>
                            </li>
                            <li>
                                <Link to="/about">ABOUT US</Link>
                            </li>
                            <li>
                                <Link to="/contact">CONTACT US</Link>
                            </li>
                        </ul>
                        <div className="text-center mt-5">
                            <Link to="/login">
                                <span className="material-symbols-outlined text-white">
                                    account_circle
                                </span>
                            </Link>
                        </div>
                    </div>
                </nav>
                <main>{children}</main>
                <div className="footer w-full h-3/4 bg-slate-300">
                    <div className="grid md:grid-cols-3 p-10">
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-2">
                                <img
                                    src={Logo}
                                    alt="logo"
                                    className="w-20 object-contain"
                                />
                                <p>PRINTS WITH STYLE</p>
                            </div>
                            <div>
                                <h3 className="font-bold text-2xl mb-5">
                                    ADDRESS
                                </h3>
                                <p>Vicentillo Ext., Brgy. P. I. Garcia</p>
                                <p>Naval, Biliran</p>
                                <p>6560, Philippines</p>
                            </div>
                        </div>
                        <div>
                            <h3 className="font-bold text-2xl mb-5 mt-5 sm:mt-0">
                                NAVIGATION
                            </h3>
                            <ul>
                                <li className="mb-2">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/shop">Shop</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/">Contact Us</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/">FAQ</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-bold text-2xl mb-5">CONTACT</h3>
                            <div className="flex items-center gap-2">
                                <span className="material-symbols-outlined">
                                    mail
                                </span>
                                <a href="/">braiceprintingshop@gmail.com</a>
                            </div>
                            <div className="flex items-center gap-2">
                                <span className="material-symbols-outlined">
                                    phone_iphone
                                </span>
                                <p>09123456789</p>
                            </div>

                            <h3 className="font-bold text-2xl my-5">SOCIALS</h3>
                            <div className="flex gap-4">
                                <a href="https://www.facebook.com/BraicePrinting">
                                    <FaFacebook size={50} />
                                </a>
                                <a href="/">
                                    <RiInstagramFill size={50} />
                                </a>
                            </div>
                        </div>
                        {/* Sidebar */}
                        <Cart
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                    </div>
                </div>
            </header>
        </div>
    );
}
