import Tshirt from "../assets/images/tshirt.jpg";
import Hoodie from "../assets/images/hoodie.jpg";
import Short from "../assets/images/short.jpg";
import Cap from "../assets/images/cap.jpg";

const Category = () => {
    return (<>
    <h1 className="text-4xl text-center mt-10">Shop by Category</h1>
        <div className="w-full grid sm:grid-cols-2 lg:grid-cols-4 gap-4 py-10">
            <div className="relative w-full h-100 group overflow-hidden cursor-pointer">
                <img
                    src={Tshirt}
                    alt="Tshirt"
                    className="w-full h-full object-cover transform transition-transform duration-1000 group-hover:scale-110"
                />
                <div className="absolute w-full h-full top-0 left-0 bg-gray-900/10"></div>
                <div className="absolute inset-0 top-0 left-0 gap-5 flex flex-col justify-center items-center text-white p-10 text-center">
                    <h2 className="text-4xl font-semibold">T-SHIRTS</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <a
                        href="/shop"
                        className="px-6 py-3 border border-white font-semibold hover:bg-slate-200/50"
                    >
                        See more
                    </a>
                </div>
            </div>
            <div className="relative w-full h-100 group overflow-hidden cursor-pointer">
                <img
                    src={Hoodie}
                    alt="Hoodie"
                    className="w-full h-full object-cover transform transition-transform duration-1000 group-hover:scale-110"
                />
                <div className="absolute w-full h-full top-0 left-0 bg-gray-900/10"></div>
                <div className="absolute inset-0 top-0 left-0 gap-5 flex flex-col justify-center items-center text-white p-10 text-center">
                    <h2 className="text-4xl font-semibold">HOODIE</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <a
                        href="/shop"
                        className="px-6 py-3 border border-white font-semibold hover:bg-slate-200/50"
                    >
                        See more
                    </a>
                </div>
            </div>
            <div className="relative w-full h-100 group overflow-hidden cursor-pointer">
                <img
                    src={Short}
                    alt="Short"
                    className="w-full h-full object-cover transform transition-transform duration-1000 group-hover:scale-110"
                />
                <div className="absolute w-full h-full top-0 left-0 bg-gray-900/10"></div>
                <div className="absolute inset-0 top-0 left-0 gap-5 flex flex-col justify-center items-center text-white p-10 text-center">
                    <h2 className="text-4xl font-semibold">SHORTS</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <a
                        href="/shop"
                        className="px-6 py-3 border border-white font-semibold hover:bg-slate-200/50"
                    >
                        See more
                    </a>
                </div>
            </div>
            <div className="relative w-full h-100 group overflow-hidden cursor-pointer">
                <img
                    src={Cap}
                    alt="Cap"
                    className="w-full h-full object-cover transform transition-transform duration-1000 group-hover:scale-110"
                />
                <div className="absolute w-full h-full top-0 left-0 bg-gray-900/10"></div>
                <div className="absolute inset-0 top-0 left-0 gap-5 flex flex-col justify-center items-center text-white p-10 text-center">
                    <h2 className="text-4xl font-semibold">CAP</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <a
                        href="/shop"
                        className="px-6 py-3 border border-white font-semibold hover:bg-slate-200/50"
                    >
                        See more
                    </a>
                </div>
            </div>
        </div></>
    );
};

export default Category;
