import React from "react";
import { truncateText } from "../utils/functions";

const Card = ({ name, price, category, image }) => {
    return (
        <div className="mx-auto bg-white shadow-sm overflow-hidden md:max-w-sm">
            <div className="">
                <div className="md:flex-shrink-0">
                    <img
                        className="max-h-96 w-full object-cover"
                        src={
                            image
                                ? `https://jeffcapstoneapi.wd99p.com${image}`
                                : `https://static.vecteezy.com/system/resources/thumbnails/022/059/000/small_2x/no-image-available-icon-vector.jpg`
                        }
                        alt="Apparel"
                    />
                </div>
                <div className="px-4 mt-3 uppercase text-sm text-green-600 font-semibold">
                    {category}
                </div>
                <div className="px-4 mb-3 flex justify-between items-center">
                    <h2 className="text-xl leading-7 font-semibold text-gray-900 truncate">
                        {name}
                    </h2>
                    <div className="uppercase text-lg text-orange-600 font-bold">
                        ₱ {price}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
