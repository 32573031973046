import Banner from "../assets/images/banner.jpg";
import Logo from "../assets/logo/logo-braice.jpg";
import { NavLink } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero w-full">
            <div className="w-full h-screen relative">
                <img
                    src={Banner}
                    alt="Cover"
                    className="w-full h-full object-cover hidden sm:block"
                />
                <img
                    src={Logo}
                    alt="Logo"
                    className="w-full h-full object-fit sm:hidden"
                />
                {/* <div className="absolute w-full h-full top-0 left-0 bg-gray-900/10"></div> */}
                <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
                    <h2 className="mb-5 text-white font-semibold text-2xl italic">PRINTS WITH STYLE</h2>
                    <NavLink to="/shop">
                        <button className="text-white text-2xl border-2 px-6 py-3 hover:bg-slate-100/30">
                            SHOP NOW
                        </button>
                    </NavLink>
                </div>
            </div>
        </div>
  )
}

export default Hero