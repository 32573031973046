import React from "react";

const Contact = () => {
    return (
        <div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1162.1034991859456!2d124.39798850029185!3d11.563707474043401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a78cb83fe5d831%3A0x8f4d4e26d86e78c3!2sBraice%20Prints%20and%20Apparel!5e0!3m2!1sen!2sph!4v1715060239068!5m2!1sen!2sph"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full"
            ></iframe>
            <div className="text-center px-96 py-20">
                <div className="mb-10">
                    <h1 className="text-4xl font-bold mb-5">CONTACT US</h1>
                    <p>
                        At BRAICE Prints and Apparels, we are dedicated to
                        ensuring that our customers have a pleasant and seamless
                        experience when visiting our webshop. We strive to
                        respond to all inquiries as quickly as possible. We are
                        here for you and happy to help you find answers to your
                        questions.
                    </p>
                </div>
                <div>
                    <h1 className="text-3xl font-bold mb-5">OPENING HOURS</h1>
                    <h2 className="text-2xl">Monday - Saturday</h2>
                    <h2 className="text-2xl">9:00 AM - 6:00 PM</h2>
                </div>
            </div>
        </div>
    );
};

export default Contact;
