import "./App.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import RootLayout from "./layout/RootLayout";
import RootLayoutAdmin from "./layout/RootLayoutAdmin";
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PageNotFound from "./pages/PageNotFound";
import Dashboard from "./pages/admin/Dashboard";
import Orders from "./pages/admin/Orders";
import Customers from "./pages/admin/Customers";
import { useState } from "react";
import Apparels from "./pages/admin/Apparels";
import ItemDetails from "./components/ItemDetails";
import Account from "./pages/Account";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        return localStorage.getItem("token") ? true : false;
    });

    const access = localStorage.getItem("access");

    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path={[
                        "/",
                        "/shop",
                        "/shop/:id/details",
                        "/about",
                        "/contact",
                        "/login",
                        "/register",
                        "/account",
                    ]}
                >
                    <RootLayout>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/shop" component={Shop} />
                            <Route
                                path="/shop/:id/details"
                                component={ItemDetails}
                            />
                            <Route path="/about" component={About} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/login" component={Login} />
                            <Route path="/register" component={Register} />
                            {isLoggedIn && access === "customer" ? (
                                <Route path="/account" component={Account} />
                            ) : (
                                <Redirect to="/login" />
                            )}
                        </Switch>
                    </RootLayout>
                </Route>

                <Route
                    path={[
                        "/admin/dashboard",
                        "/admin/apparels",
                        "/admin/orders",
                        "/admin/customers",
                    ]}
                >
                    {isLoggedIn && access === "admin" ? (
                        <RootLayoutAdmin>
                            <Switch>
                                <Route
                                    exact
                                    path="/admin/dashboard"
                                    component={Dashboard}
                                />
                                <Route
                                    path="/admin/apparels"
                                    component={Apparels}
                                />
                                <Route
                                    path="/admin/orders"
                                    component={Orders}
                                />
                                <Route
                                    path="/admin/customers"
                                    component={Customers}
                                />
                            </Switch>
                        </RootLayoutAdmin>
                    ) : (
                        <Redirect to="/login" />
                    )}
                </Route>

                <Route path="*" component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
