import { useEffect, useState } from "react";
import ShopBanner from "../assets/images/shop-banner.jpg";
import Card from "../components/Card";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";

const Shop = () => {
    const [apparels, setApparels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const fetchAllApparels = async () => {
        try {
            const response = await fetch(
                "https://jeffcapstoneapi.wd99p.com/api/v1/apparels/all"
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result);
            setApparels(result);
            setIsLoading(false);
        } catch (error) {
            console.error("Fetch failed: ", error);
        }
    };

    useEffect(() => {
        fetchAllApparels();
        console.log(apparels)
    }, []);

    return (
        <div className="w-full pb-20">
            <div className="relative">
                <div className="absolute top-0 left-0 h-full w-full bg-gray-500/50"></div>
                <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                    <h1 className="text-9xl font-bold text-white">SHOP</h1>
                </div>
                <img
                    src={ShopBanner}
                    alt="Shop Banner"
                    className="object-cover w-full"
                />
            </div>
            {isLoading ? (
                <div className="mt-20">
                    <Spinner />
                </div>
            ) : (
                <div className="w-full px-5 grid gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {apparels.map((apparel) => (
                        <div
                            key={apparel.id}
                            className="container mx-auto mt-8"
                        >
                            <Link to={`/shop/${apparel.id}/details`}>
                                <Card
                                    name={apparel.name}
                                    description={apparel.description}
                                    size={apparel.size}
                                    price={apparel.price}
                                    quantity={apparel.quantity}
                                    category={apparel.category}
                                    image={apparel.image_url}
                                />
                            </Link>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Shop;
