import AboutBanner from "../assets/images/about-banner.jpg";
import SketchBanner from "../assets/images/sketch-banner.jpg";
import Team from "../assets/images/team.jpg";

const About = () => {
    return (
        <div>
            <div className="relative">
                <img
                    src={AboutBanner}
                    alt="About Us Banner"
                    className="object-cover"
                />
                <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                    <h1 className="text-white text-6xl font-semibold">ABOUT US</h1>
                </div>
            </div>
            <div className="my-32 text-center">
                <h1 className="text-4xl font-semibold mb-5">OUR STORY</h1>
                <p className="mb-5">
                    The world doesn’t need more clothes, it needs better
                    clothes.
                </p>
                <p>
                    We are passionated about timeless and responsible clothes
                    that you will wear again and again, season after season.{" "}
                    <br /> All our production and consumption must take place{" "}
                    <br />
                    without harming our planet for the sake of our future
                    generations.
                </p>
            </div>
            <div className="relative my-10">
                <img
                    src={SketchBanner}
                    alt="Sketch Banner"
                    className="w-full"
                />
                <div className="absolute w-full h-full top-0 left-0 bg-gray-500/50"></div>
                <div className="absolute w-full h-full top-0 left-0 grid grid-cols-2 overflow-hidden">
                    <div className="col-start-2 flex flex-col justify-center items-center p-40 text-white text-lg bg-slate-500/30">
                        <h1 className="text-2xl font-semibold">OUR STANDARD</h1>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Obcaecati, labore. Eaque iure ducimus non
                            doloribus! Fuga nisi voluptates corporis eaque!
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Provident consequatur commodi amet quia neque
                            totam, tenetur in impedit saepe eaque.
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 p-20">
                <div>
                    <img src={Team} alt="Team" className="shadow-lg" />
                </div>
                <div className="flex flex-col justify-center pr-40">
                    <h1 className="text-2xl font-semibold mb-5">OUR TEAM</h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Obcaecati, labore. Eaque iure ducimus non doloribus!
                        Fuga nisi voluptates corporis eaque! Lorem ipsum dolor
                        sit amet consectetur adipisicing elit. Provident
                        consequatur commodi amet quia neque totam, tenetur in
                        impedit saepe eaque.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;
