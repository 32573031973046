import React from 'react'
import Hero from '../sections/Hero'
import Category from '../sections/Category'

const Home = () => {
  return (
    <div>
      <Hero />
      <Category />
    </div>
  )
}

export default Home