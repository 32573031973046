import React, { useEffect, useState } from "react";
import CartCard from "./CartCard";

const Cart = ({ sidebarOpen, setSidebarOpen }) => {
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCartItems = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(
                `https://jeffcapstoneapi.wd99p.com/api/v1/cart/all`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log(result.cart_items);
            console.log(result.cart_items);

            setCartItems(result.cart_items);
            setIsLoading(false);
        } catch (error) {
            console.error("Fetch failed: ", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCartItems();
    }, []);
    return (
        <div>
            <div
                className={`fixed right-0 top-0 w-96 z-20 h-full bg-white shadow-lg transition-transform transform ${
                    sidebarOpen ? "translate-x-0" : "translate-x-full"
                }`}
            >
                <div className="p-4">
                    <div className="flex justify-between">
                        <h1 className="text-2xl font-bold">
                            CART ({cartItems.length})
                        </h1>
                        <button
                            onClick={() => setSidebarOpen(false)}
                            className="text-xl font-bold"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="divide-slate-500/90 divide-y-2 mt-5">
                        {cartItems.map((item) => (
                            <CartCard key={item.id} item={item} />
                        ))}
                    </div>
                    <div className="flex justify-between border-b-2 pb-5">
                        <p>Total</p>
                        <p>1000 php</p>
                    </div>
                    <button className="w-full py-4 font-bold text-xl bg-lime-500/80 hover:bg-lime-400/80">
                        Check Out
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Cart;
